<template>
  <div class="parent">
    <div class="wrapper">
      <tools-icon/>
      <span class="text">{{ $t('app.under-construction') }}...</span>
    </div>

    <a :href="redirectUrl"
       target="_blank"
       class="url">Configurar en hey!</a>
  </div>
</template>

<script>
export default {
  computed: {
    redirectUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/admin/campaigns`;
    },
  },
};
</script>

<style scoped lang="scss">
  .parent {
    display: flex;
    align-items: center;
    flex-direction: column;

    .wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 10px;
      justify-content: center;

      .material-design-icon {
        font-size: 32px;
        color: $blue-alt;
        margin: 0 8px 6px 0;
      }

      .text {
        font-size: 18px;
      }
    }

    .url {
      color: $blue;
      font-size: 16px;
      text-align: center;
      text-decoration: underline;
    }
  }
</style>
